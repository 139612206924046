import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import format from "date-fns/format";
import axios from "axios";
import distinctColors from "distinct-colors";
import Flash from "../common/flash";
import "../../css/calendar.css";
import { checkGroups } from "../helpers/checkGroupName";

class Calendar extends Component {
  constructor(props) {
    super();
    this.state = {
      modal: false,
      groupColors: null,
      locationColors: null,
      checkedList: null,
      checkedLocations: null,
      weekendsVisible: true,
      link: null,
      focus: false,
    };
  }

  calendarRef = React.createRef();
  // get all the groups from the database and assign each a color for the calendar
  componentDidMount() {
    checkGroups().then((response) => {
      console.log(response)
      if (response.error) { this.setFlash(("SYS!:" + response.error), false); }
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const group = urlParams.has("group")
      ? urlParams.get("group").split(",").filter(i => i)
      : false;
    const location = urlParams.has("location")
      ? urlParams.get("location").split(",")
      : false;
    //if the calendar is showing on a shared link
    if (group && location) {
      this.assignGroupColors(group, group, location);
      this.setState({ checkedList: group, checkedLocations: location });
    } else {
      // calendar on the shiftr app
      let localGroup = localStorage.getItem("group")
        ? localStorage.getItem("group").split(",").filter(i => i)
        : [];
      axios
        .get("shifts/all_groups")
        .then((response) => {
          let findGroup = localGroup.filter((oneGroup) =>
            response.data.includes(oneGroup)
          );
          this.setState({ checkedList: findGroup });
          this.assignGroupColors(localGroup, this.state.checkedList, false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.insertStatusRole();
  }

  // if start or end date is updated, update fullcalendar
  componentDidUpdate(prevState) {
    if (
      prevState.checkedList !== this.state.checkedList ||
      prevState.checkedLocations !== this.state.checkedLocations
    ) {
      this.calendarRef.current.getApi().refetchEvents(); // refresh fullcalendar
    }
  }

  // sets groupColors state (list of dictionaries in format {group: 'name', color: 'hex code'})
  // sets locationColors state (list of dictionaries in format {location: 'name', color: [array of chroma-js objects from distinct-colors]})
  assignGroupColors = async (groups, checkedList, isLink) => {
    let colors = [
      "#F5737B",
      "#F7A7FF",
      "#81A0FB",
      "#FFA5AA",
      "#A8BEFF",
      "#9F93F7",
      "#BCB4FB",
    ];
    let groupColors = [];
    for (let i = 0; i < groups.length; i++) {
      let group = groups[i].trim();
      let response = await axios.get("publicCalendars/find/" + group);
      if (response.status !== 200) {
        console.log(
          "Did not find this group:",
          group,
          ", got error:",
          response.status
        );
      } else if (response.data) {
        let locations;
        if (response.data.locations) {
          locations = response.data.locations;
          if (isLink) {
            locations = [];
            response.data.locations.forEach((location) => {
              if (isLink.includes(location)) {
                locations.push(location);
              }
            });
          }
        }
        groupColors.push({
          group: group,
          color: colors[i],
          locations: locations,
        });
      }
    }
    let locations = [];
    let allLocations = [];
    groupColors.forEach((groupName) => {
      groupName.locations.forEach((location) => {
        if (checkedList.includes(groupName.group)) {
          locations.push(location);
        }

        if (!allLocations.includes(location)) {
          allLocations.push(location);
        }
      });
    });
    this.setState({ checkedLocations: locations });
    this.setState({ groupColors: groupColors });

    //SET LOCATION COLORS
    let pallette = distinctColors({ count: allLocations.length }); //location colors assigned here
    let locationColors = [];
    for (let i = 0; i < allLocations.length; i++) {
      locationColors.push({ location: allLocations[i], color: pallette[i] });
    }
    this.setState({ locationColors: locationColors });
  };

  // gives a role=status prop to the date on Calendar
  // this is so screen readers announce the displayed date after users click <, today, or > buttons
  insertStatusRole = () => {
    let date = document.getElementsByClassName('fc-header-toolbar fc-toolbar fc-toolbar-ltr')[0];
    date.setAttribute("role", "status");
  }

  // overrides fullcalendar's event display
  //causes error about rendering
  renderEventContent = (eventInfo) => {
    let opacity = 1;
    let focus = true;

    //set opacity based on focus state
    if (this.state.focus && this.state.focus.netId !== eventInfo.event.extendedProps.netId) {
      // console.log(this.state.focus.netId, " != ", eventInfo.event.extendedProps.netId)
      opacity = 0.3;
      focus = false;
    }

    if (this.state && this.state.groupColors) {

      // group's assigned color
      let color = this.state.groupColors.find(
        (group) => group.group === eventInfo.event.extendedProps.group
      );
      let name;
      if (eventInfo.event.extendedProps.display_name) {
        name = eventInfo.event.extendedProps.display_name.split(" ");
        name = name[1] ? `${name[0]} ${name[1][0]}.` : name[0];
      } else {
        name = eventInfo.event.extendedProps.name
          ? eventInfo.event.extendedProps.name
          : "unknown";
      }
      const location = eventInfo.event.extendedProps.location;
      if (eventInfo.view.type === "dayGridMonth") {
        return this.renderMonthCell(eventInfo, name, location, color, focus);
      }

      let locationColor = "grey";
      let locationColors = this.state.locationColors;
      if (locationColors && locationColors.length > 0) {
        locationColor = locationColors.find(
          (location) =>
            location.location === eventInfo.event.extendedProps.location
        ).color;
      }

      if (eventInfo.view.type === "timeGridWeek" && color) {
        return (
          // light gray rectangle with left border in group's color
          <div
            className="event"
            style={{
              borderLeft: `5px solid ${color.color}`,
              alignItems: "center",
              opacity: opacity,
            }}
          >
            {/* group name | location of shift */}
            <p className="bold"> {name} </p>
            <p
              style={{ display: "inline-block" }}
            >{location}</p>
            <div
              style={{
                padding: "5px",
                borderRadius: "10px",
                backgroundColor: locationColor,
                display: "inline-block",
                marginLeft: "5px",
              }}
            ></div>
          </div>
        );
      }

      if (eventInfo.view.type === "dayGridDay" && color) {
        return (
          // light gray rectangle with left border in group's color
          <div
            className="event"
            style={{
              borderLeft: `5px solid ${color.color}`,
              alignItems: "center",
              opacity: opacity,
            }}
          >
            {/* group name | location of shift */}
            <p className="bold">
              <span
                style={{
                  padding: "5px",
                  borderRadius: "10px",
                  backgroundColor: locationColor,
                  display: "inline-block",
                  marginLeft: "5px",
                }}
              ></span>
              {format((eventInfo.event.start), "h:mma")}&#8211;{format((eventInfo.event.end ? eventInfo.event.end : eventInfo.event.start), "h:mma")} </p>
            <p
              style={{ display: "inline-block" }}
            >{name} | {eventInfo.event.extendedProps.location} | {eventInfo.event.extendedProps.note}</p>
          </div>
        );

      }

      if (color) {
        return (
          // light gray rectangle with left border in group's color
          <div
            className="event"
            style={{ borderLeft: `5px solid ${color.color}`, opacity: opacity }}
          >
            {/* group name | location of shift */}
            <p className="bold"> {name} </p>
            <p>{location}{eventInfo.event.extendedProps.note ? ` | ${eventInfo.event.extendedProps.note}` : ''}</p>
          </div>
        );
      }
    }
  };

  renderMonthCell = (eventInfo, name, location, color, focus) => {
    //Focus
    let opacity = 1;
    if (!focus) {
      opacity = 0.3;
    }

    //Color codiing
    let locationColor = this.state.locationColors.find(
      (location) => location.location === eventInfo.event.extendedProps.location
    ).color;

    let r = locationColor._rgb[0];
    let g = locationColor._rgb[1];
    let b = locationColor._rgb[2];

    return (
      <div
        className="event"
        style={{
          backgroundColor: `rgba(${r},${g},${b},0.5)`,
          borderRadius: "10px",
          overflow: "hidden",
          zIndex: 6,
          eventDisplay: "auto",
          margin: "1px",
          padding: "1px",
          alignItems: "center",
          opacity: opacity,
        }}
      >
        <div
          style={{
            backgroundColor: color.color,
            padding: "5px",
            borderRadius: "10px",
            margin: "1px",
            display: "inline-block",
          }}
        ></div>
        <p
          style={{ display: "inline-block" }}
        >
          {name} - {location}
        </p>
      </div>
    );
  };

  // when event is clicked, show modal
  handleEventClick = (clickInfo) => {
    this.toggleModal(clickInfo);
    this.toggleFocus(clickInfo);
  };

  // actual updating of state to show modal
  toggleModal(clickInfo) {
    axios
      .get(
        "schedule/findByScheduleId/" + clickInfo.event.extendedProps.scheduleId
      )
      .then((response) => {
        this.setState({ modal: clickInfo, schedule: response.data.body });
      });
  }

  //changes/removes individual being highlighted
  toggleFocus(clickInfo) {
    // console.log("CLICKINFO: ", clickInfo.el);
    if (
      !this.state.focus ||
      this.state.focus.netId !== clickInfo.event._def.extendedProps.netId
    ) {
      this.setState({ focus: clickInfo.event._def.extendedProps });
    } else {
      this.setState({ focus: false });
    }
  }

  //displays name of individual being highlighted
  renderFocus = () => {
    if (this.state.focus) {
      return (
        <h3>
          <span
            style={{ fontWeight: "bold" }}
          >Highlighting:</span>{" "}
          {this.state.focus.display_name}
        </h3>
      );
    }
  };

  // format date. show minutes if time isn't on the hour. (example: 9AM, 6:30PM)
  displayDate(date) {
    if (format(date, "m") === "0") {
      return format(date, "ha");
    } else {
      return format(date, "h:mma");
    }
  }

  // modal with more details about the shift
  shiftInfoModal = () => {
    if (this.state.modal) {
      let scheduleName;
      let extendedProps = this.state.modal.event._def.extendedProps;
      // console.log("extendedProps: ", extendedProps)
      if (
        localStorage.getItem("role") === "supervisor" ||
        localStorage.getItem("role") === "admin"
      ) {
        scheduleName = (
          <p>
            <span className="bold">Schedule: </span>
            {this.state.schedule ? this.state.schedule.scheduleName : "Deleted"}
          </p>
        );
      }

      let { start_time, end_time } = this.correctTimeZone(); // correct fullcalendar time zone bug
      return (
        <div>
          <p className="title is-5">Shift Details</p>
          {/* time range of shift */}
          <p>
            {format(start_time, "h:mm a")} &#8211; {format(end_time, "h:mm a")}
          </p>

          {/* more details about the shift */}
          <section>
            <p>
              <span className="bold">Group: </span>
              {extendedProps.group}
            </p>
            <p>
              <span className="bold">Location: </span>
              {extendedProps.location}
            </p>
            {scheduleName}
            <p>
              <span className="bold">Employee: </span>
              {extendedProps.display_name
                ? extendedProps.display_name
                : extendedProps.name}
            </p>
            <p hidden={extendedProps.note ? false : true}>
              <span className="bold">Note: </span>
              {extendedProps.note}
            </p>
          </section>
        </div>
      );
    }
  };

  // corrects fullcalendar bug which incorrectly adgjusts for time zone
  correctTimeZone() {
    let start_time = this.state.modal.event._instance.range.start;
    let end_time = this.state.modal.event._instance.range.end;
    let tzDifference = start_time.getTimezoneOffset();

    start_time = new Date(start_time.getTime() + tzDifference * 60 * 1000);
    end_time = new Date(end_time.getTime() + tzDifference * 60 * 1000);

    return { start_time, end_time };
  }

  // sidebar with group checkboxes and option to add/remove weekends
  renderSidebar = () => {
    if (this.state.groupColors && this.state.checkedLocations) {
      let groupColors = this.state.groupColors;
      let groups = groupColors.map((group, index) => (
        <div
          key={group + index}
          onClick={this.toggleGroup}
          style={{ cursor: "pointer" }}
        >
          {/* if unchecked, box is gray. if checked, box is color specified in groupColors state */}
          <span
            style={{
              backgroundColor: this.state.checkedList.includes(group.group)
                ? group.color
                : "#EDEDED",
            }}
            className="group-box"
          ></span>
          <p name={group.group}>&nbsp; {group.group}</p>
        </div>
      ));
      let locations = [];

      groupColors.forEach((group) => {
        let locationColors = this.state.locationColors;

        if (this.state.checkedList.includes(group.group) && locationColors) {
          group.locations.forEach((location, index) => {
            // let locationColor = this.state.locationColors.find(location => location.location === location);
            // console.log("locationColor!!!!!: ", locationColor)

            //finds index of locationColors array that has the same location as param location
            let colorIndex = -1;
            for (let i = 0; i < locationColors.length; i++) {
              if (locationColors[i].location === location) {
                colorIndex = i;
              }
            }

            locations.push(
              <div
                key={`${group.group}-${location}-${index}`}
                onClick={this.toggleLocation}
                style={{ cursor: "pointer" }}
              >
                {/* if unchecked, box is gray. if checked, box is color specified in groupColors state */}
                <span
                  style={{
                    backgroundColor: this.state.checkedLocations.includes(
                      location
                    )
                      ? locationColors[colorIndex].color
                      : "#EDEDED",
                  }}
                  className="group-box"
                ></span>

                <p name={location}>&nbsp; {location}</p>
              </div>
            );
          });
        }
      });

      return (
        <div className="legend-groups">
          {/* differently colored check box for each group */}
          <div>
            <h3>Groups:</h3>
            {groups}
          </div>
          <div>
            <h3>Locations:</h3>
            <div className="control">{locations}</div>
          </div>
        </div>
      );
    }
  };

  // add or remove a location from the calendar display
  toggleLocation = (event) => {
    let locations = this.state.checkedLocations;
    let location = event.target.getAttribute("name");

    // after unchecking a group, remove it from checkedList state
    if (locations.includes(location)) {
      let index = locations.findIndex((element) => element === location);
      locations.splice(index, 1);
    } else {
      locations.push(location);
      // this.calendarRef.current.getApi().refetchEvents()
    }
    this.setState({ checkedLocations: locations });
  };

  // add or remove a group from the calendar display
  toggleGroup = (event) => {
    let groups = this.state.checkedList;
    let group = event.target.getAttribute("name");

    // after unchecking a group, remove it from checkedList state
    if (groups.includes(group)) {
      let index = groups.findIndex((element) => element === group);
      groups.splice(index, 1);
    }
    // after checking a new group, add it to the checkedList state and refresh fullcalendar
    else {
      groups.push(group);
      // this.calendarRef.current.getApi().refetchEvents()
    }
    let locations = [];
    this.state.groupColors.forEach((groupName) => {
      if (groups.includes(groupName.group)) {
        groupName.locations.forEach((location) => {
          locations.push(location);
        });
      }
    });
    this.setState({ checkedList: groups, checkedLocations: locations });
  };

  drawCalendar() {
    let self = this;
    let locationColors = this.state.locationColors;
    let selfState = self.state;
    return (
      <div className="card">
        <div className="card-content">
          <FullCalendar
            ref={this.calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
            headerToolbar={{
              // calendar header with navigation options
              left: "prev,today,next",
              center: "title",
              right: "dayGridMonth,timeGridWeek,listWeek,dayGridDay",
            }}
            initialView="listWeek"
            dayHeaderFormat={{ weekday: "long" }} // show days of the week at the top of the calendar
            height={600} // TODO: reasonable responsive height
            showNonCurrentDates={false} // month calendar grays out days not in current month
            weekends={this.state.weekendsVisible} // weekends shown based on state/toggle button
            allDaySlot={false} // no all day events
            nowIndicator={true} // red line and arrow to indicate current time
            scrollTime={"07:00:00"} // week/day view begins at 7 am
            eventColor={"#EDEDED"} // event background color = light gray
            eventTextColor={"black"} // event text color = black
            eventContent={this.renderEventContent.bind(this)} // customized event layout
            eventClick={this.handleEventClick.bind(this)} // method to show modal when calendar is clicked
            // slotEventOverlap={false}
            initialEvents={
              // get all shifts within time range from backend
              function (info, successCallback, failureCallback) {
                if (
                  self.state.checkedLocations &&
                  self.state.checkedLocations.length > 0 &&
                  self.state.checkedList.length > 0
                ) {
                  // console.log("MAKING THE CALL");
                  axios
                    .get(
                      "publicCalendars/find_time_group_location_fc/" +
                      info.start.valueOf() / 1000 +
                      "/" +
                      info.end.valueOf() / 1000 +
                      "/" +
                      self.state.checkedList +
                      "/" +
                      self.state.checkedLocations
                    )
                    .then((response) => {
                      successCallback(response.data);
                    })
                    .catch((error) => {
                      failureCallback(error);
                    });
                } else {
                  successCallback([]);
                }
              }
            }
            views={{
              listWeek: {
                validRange:
                  function (nowDate) {
                    return {
                      start: nowDate // Restrict listWeek to start from today
                    };
                  }
              }
            }}
            eventDidMount={(eventInfo) => {
              //Location coloring
              const location = eventInfo.event.extendedProps.location;
              let locationColor = "grey";
              if (locationColors && locationColors.length > 0) {
                locationColor = locationColors.find((l) => l.location === location).color;
              }


              // Change color of dot marker in List View according to location
              var dotEl = eventInfo.el.getElementsByClassName("fc-list-event-dot")[0];
              if (dotEl) {
                dotEl.style.backgroundColor = locationColor;
                dotEl.style.borderColor = locationColor;
                dotEl.style.padding = "2px";
                dotEl.style.borderRadius = "10px";
                // eventDidMount - called right after the element has been added to the DOM.
                // If the event data changes, this is NOT called again.
              }

              //Focus
              if (selfState.focus) {
                if (selfState.focus.netId === eventInfo.event.extendedProps.netId) {
                  var element = eventInfo.el;
                  var parent = element.parentElement;
                  if (parent) {
                    parent.style.setProperty("z-index", 100, "important");
                  }
                } else {
                  if (dotEl) {
                    dotEl.style.opacity = 0.3;
                  }
                }
              }
            }
            }
          />
        </div>
      </div>
    );
  }

  closeFlash = () => {
    this.setState({ error: false, success: false });
  };

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  };

  //show error/success message
  flash = () => {
    if (this.state.error) {
      let errTitle = "Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0, 5) === "SYS!:") {
        errTitle = "System Error";
        errMessage = errMessage.slice(5, errMessage.length);
      }

      return (
        <Flash
          type="error"
          title={errTitle}
          messages={[errMessage]}
          closeFlash={this.closeFlash}
        />
      );
    }

    if (this.state.success) {
      return (
        <Flash
          type="success"
          title="Success"
          messages={this.state.success}
          closeFlash={this.closeFlash}
        />
      );
    }
  };

  //get the link and copy it to the clipboard
  getLink = () => {
    let group = this.state.checkedList.join().replace(/\s+/g, '%20');
    let location = this.state.checkedLocations.join().replace(/\s+/g, '%20');
    let text = `${window.location.host}/iframe/?group=${group}&location=${location}`;
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ success: ["Link copied to clipboard!"] });
      },
      function (err) {
        this.setState({ error: [err] });
      }
    );
  };

  //create link to share with others
  linkButton() {
    if (
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "supervisor"
    ) {
      return (
        <div className="field">
          <div className="control">
            <button
              // className="button is-light"
              className="button"
              type="button"
              onClick={this.getLink}
            >
              Schedule Link
            </button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.flash()}
        {/* <div className="full-calendar columns"> */}
        {this.props.colabWebsite ? (
          <div className="calendar">
            {this.drawCalendar()}
          </div>
        ) : (
          <div className="full-calendar">
            {/* sidebar */}
            {/* <div className="column is-2 sidebar"> */}
            <div className="legend">
              {this.renderSidebar()}

              {this.linkButton()}

              {/* modal */}
              {this.shiftInfoModal()}
            </div>

            {/* calendar */}
            {/* <div className="column"> */}
            <div className="calendar">
              {this.drawCalendar()}
              <div className="focus">{this.renderFocus()}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Calendar;
