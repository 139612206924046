import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

export default class TextReminder extends Component {

    state = {
        weeklyReminder: {}, dailyReminder: {}, hourlyReminder: {}, timecardReminder: {}
    }

    componentDidMount() {
        this.setState({ weeklyReminder: this.props.weeklyReminder, dailyReminder: this.props.dailyReminder, 
            hourlyReminder: this.props.hourlyReminder, timecardReminder: this.props.timecardReminder });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.weeklyReminder !== this.props.weeklyReminder || prevProps.dailyReminder !== this.props.dailyReminder || prevProps.hourlyReminder !== this.props.hourlyReminder || prevProps.timecardReminder !== this.props.timecardReminder) {
            this.setState({ weeklyReminder: this.props.weeklyReminder, dailyReminder: this.props.dailyReminder, 
                hourlyReminder: this.props.hourlyReminder, timecardReminder: this.props.timecardReminder });
        }
    }

    setReminder = (event, type) => {
        event.preventDefault();
        let target = event.target;
        if (type === "weeklyReminder") {
            if (window.confirm(`Do you want to set a ${type} on ${target.day.value === '1' ? "Monday" : "Sunday"} at ${target.time.value}?`)) {
                let weeklyReminder = {
                    day: target.day.value,
                    time: target.time.value
                }
                // console.log(weeklyReminder);
                this.setState({ [type]: weeklyReminder });
                this.props.updateGroup({ [type]: weeklyReminder });
            }
        } else if (type === "dailyReminder") {
            if (window.confirm(`Do you want to set a ${type} at ${target.time.value}?`)) {
                let dailyReminder = {
                    time: target.time.value
                }
                // console.log(dailyReminder);
                this.setState({ [type]: dailyReminder });
                this.props.updateGroup({ [type]: dailyReminder });
            }
        } else if (type === "hourlyReminder") {
            if (window.confirm(`Do you want to set a ${type} ${target.minutes.value} mins before each shift starts?`)) {
                let hourlyReminder = {
                    minutes: target.minutes.value
                }
                // console.log(hourlyReminder);
                this.setState({ [type]: hourlyReminder });
                this.props.updateGroup({ [type]: hourlyReminder });
            }
        }
    }

    endReminder = (type) => {
        if (window.confirm(`Do you want to end ${type}?`)) {
            this.setState({ [type]: {} });
            this.props.updateGroup({ [type]: {} });
        }
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const id = e.target.id;
        let newId = this.state[id]
        newId[name] = value;
        this.setState({ id: newId });
    }

    textReminder = () => {
        return <div className="field">
            <h2 className="title is-5">Text Reminder
                <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.props.setModalType('textReminder')} />
            </h2>
            <ul>
                <li>
                    <form onSubmit={(e) => this.setReminder(e, "weeklyReminder")}>
                        <div className="field is-grouped">
                            <div className="control">
                                <label className="label">Weekly on:</label>
                                <select name="day" id="weeklyReminder" value={this.state.weeklyReminder.day} onChange={this.handleChange}>
                                    <option value="none" hidden></option>
                                    <option value={'7'}>Sunday</option>
                                    <option value={'1'}>Monday</option>
                                </select>
                            </div>
                            <div className="control">
                                <label className="label">At:</label>
                                <input name="time" id="weeklyReminder" type="time" value={this.state.weeklyReminder.time || ''} onChange={this.handleChange} />
                            </div>
                            <div className="control">
                                <label className="label">Action:</label>
                                <button className="button" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                            </div>
                            {this.state.weeklyReminder.time ? <EndReminderButton type="weeklyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                        </div>
                    </form>
                </li>
                <li>
                    <form onSubmit={(e) => this.setReminder(e, "dailyReminder")}>
                        <div className="field is-grouped">
                            <div className="control">
                                <label className="label">Daily At:</label>
                                <input name="time" type="time" id="dailyReminder" value={this.state.dailyReminder.time || ''} onChange={this.handleChange} />
                            </div>
                            <div className="control">
                                <label className="label">Action:</label>
                                <button className="button" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                            </div>
                            {this.state.dailyReminder.time ? <EndReminderButton type="dailyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                        </div>
                    </form>
                </li>
                <li>
                    <form onSubmit={(e) => this.setReminder(e, "hourlyReminder")}>
                        <div className="field is-grouped">
                            <div className="control">
                                <label className="label">Minutes Before shift:</label>
                                <input name="minutes" type="number" id="hourlyReminder" min="0" value={this.state.hourlyReminder.minutes || ''} onChange={this.handleChange} />
                            </div>
                            <div className="control">
                                <label className="label">Action:</label>
                                <button className="button" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                            </div>
                            {this.state.hourlyReminder.minutes ? <EndReminderButton type="hourlyReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    }

    setTimecardReminder = (event) => {
        event.preventDefault();
        let target = event.target;
        if (window.confirm(`Do you want to set a timecard reminder on ${target.day.value === '1' ? "Monday" : "Sunday"} at ${target.time.value}?`)) {
            let timecardReminder = {
                day: target.day.value,
                time: target.time.value,
            }
            // console.log(weeklyReminder);
            this.setState({ timecardReminder: timecardReminder });
            this.props.updateGroup({ timecardReminder: timecardReminder });
        }
    }

    timecardReminder = () => {
        return <div className="field">
            <h2 className="title is-5">Timecard Reminder
                <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.props.setModalType('timecardReminder')} />
            </h2>
            <ul>
                <li>
                    <form onSubmit={this.setTimecardReminder}>
                        <div className="field is-grouped">
                            <div className="control">
                                <label className="label">Bi-Weekly on:</label>
                                <select name="day" id="timecardReminder" value={this.state.timecardReminder.day} onChange={this.handleChange}>
                                    <option value="none" hidden></option>
                                    <option value={'6'}>Saturday</option>
                                    <option value={'7'}>Sunday</option>
                                    <option value={'1'}>Monday</option>
                                </select>
                            </div>
                            <div className="control">
                                <label className="label">At:</label>
                                <input name="time" id="timecardReminder" type="time" value={this.state.timecardReminder.time || ''} onChange={this.handleChange} />
                            </div>
                            <div className="control">
                                <label className="label">Action:</label>
                                <button className="button" type="submit" disabled={this.props.waitOnQueue}>Set Reminder</button>
                            </div>
                            {this.state.timecardReminder.time ? <EndReminderButton type="timecardReminder" endReminder={this.endReminder} disabled={this.props.waitOnQueue} /> : ''}
                        </div>
                    </form>
                </li>
            </ul>
        </div>
    }

    render() {
        return <>
            {this.textReminder()}
            {this.timecardReminder()}
        </>
    }
}

function EndReminderButton(props) {
    return (
        <div className="control">
            <label className="label">Action:</label>
            <button className="button" type="button" onClick={() => props.endReminder(props.type)} disabled={props.disabled}>End Reminder</button>
        </div>
    )
}