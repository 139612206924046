import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretSquareDown, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ShiftsDetailTable from './shiftsDetailTable.js';
import WeeklyHourTable from './weeklyHourTable.js';

// display employees shifts summary and shifts details
class SupervisorView extends Component {
   constructor(props) {
      super()
      this.state = { selectedEmployee: false, users: false }
   }

   componentDidMount() {
      this.setState({ users: this.props.data });
   }

   componentDidUpdate(prevProps) {
      let changedProps = prevProps.data !== this.props.data
      if (changedProps) {
         this.setState({ users: this.props.data })
         if (this.state.selectedEmployee) {
            let employee = this.state.selectedEmployee.display_name;
            this.props.data.forEach(user => {
               if (user.display_name === employee) {
                  this.setState({ selectedEmployee: user })
                  employee = false;
               }
            });
            if (employee || this.props.data.length < 1) {
               this.setState({ selectedEmployee: false })
            }
         }
      }
   }

   toggleEmployee = (employee) => {
      if (this.state.selectedEmployee) {
         this.clearSelectedEmployee()
      }
      this.selectEmployee(employee);
   }

   clearSelectedEmployee = () => {
      this.setState({ selectedEmployee: false })
   }

   selectEmployee = (employee) => {
      window.scrollTo(0, document.body.scrollHeight);
      this.setState({ selectedEmployee: employee })
   }

   toggleDetails = () => {
      this.setState({ showDetails: !this.state.showDetails })
   }

   drawSelectedEmployee = () => {
      if (this.state.selectedEmployee) {
         let employeeShifts;
         let shiftDetails;
         if (this.state.selectedEmployee.details.shifts.length > 0) {
            shiftDetails = <button className="button is-light" onClick={this.toggleDetails}>{this.state.showDetails ? "Hide Shifts Details" : "Show Shifts Details"}</button>
            employeeShifts = <ShiftsDetailTable getShifts={this.props.getShifts} data={this.state.selectedEmployee.details} type={this.props.type} />
            return <div>
               <br />
               <h1 className="title is-5">{this.state.selectedEmployee.display_name}</h1>
               {shiftDetails}
               {<p>You may not see the employee's full shift history because the employee may clocked hours for multiple groups.</p>}
               <br />
               <WeeklyHourTable data={this.props.data} getShifts={this.props.getShifts}
                  startDate={this.props.startDate} endDate={this.props.endDate} selectedEmployee={this.state.selectedEmployee} />
               <br />
               {this.state.showDetails ? employeeShifts : ''}
            </div>
         } else {
            return <div>
               <h1 className="title is-6">{this.state.selectedEmployee.display_name} has no completed shifts. </h1>
            </div>
         }
      }
   }

   drawShifts = () => {
      if (this.state.users) {
         return <div>
            <table className="table is-bordered is-striped is-narrow is-fullwidth">
               <thead>
                  <tr>
                     <th><h1 className='title is-6 table-head'>Name</h1></th>
                     <th><h1 className='title is-6 table-head'>NetId</h1></th>
                     <th><h1 className='title is-6 table-head'>Hours Completed</h1></th>
                     <th><h1 className='title is-6 table-head'>Hours Incomplete</h1></th>
                     <th><h1 className='title is-6 table-head'>Shifts Picked Up</h1></th>
                     <th><h1 className='title is-6 table-head'>Shifts Incomplete</h1></th>
                     <th><h1 className='title is-6 table-head'>Action</h1></th>
                  </tr>
               </thead>
               <tbody>
                  {this.mapShifts()}
               </tbody>
            </table>
         </div>
      }
   }

   checkHour = (total, complete) => {
      if (total < complete) { return 0 }
      return Math.round((total - complete) / 360) / 10
   }

   mapShifts = () => {
      if (this.state.users) {
         return this.state.users.map((user, index) => {
            let row;
               row = <tr key={index}>
                  <td> {user.display_name || user.name}
                     {user.details.shifts.length > 0 && user.details.total_clocked_hours === 0 ? <span className="tag is-warning space">No Hours</span> : ''}
                     {user.details.shifts.length === 0 ? <span className="tag is-warning is-light space">No Shifts</span> : ''}</td>
                  <td> {user.netid}</td>
                  <td> {Math.round((user.details.total_clocked_hours) / 360) / 10} </td>
                  <td> {this.checkHour(user.details.total_shift_hours, user.details.total_clocked_hours)} </td>
                  <td> {user.details.shifts.length}</td>
                  <td> {user.details.shifts.length - user.details.completed}</td>
                  <td>
                     <div className="table-icons">
                        <span><FontAwesomeIcon className="clickable" icon={faCaretSquareDown} onClick={this.toggleEmployee.bind(this, user)} /> </span>
                     </div>
                  </td>
               </tr>
            return row;
         })
      }
   }

   headerAndInstructions() {
      return <>
         <span className="hover-undefined">
            Types explained:
            <FontAwesomeIcon className="fa-question-circle fa-fw" icon={faQuestionCircle} />
         </span>
         <div className="hide">
            <p><span className="bold">Complete:</span> The student's work duration is same as or greater than the shift duration.</p>
            <p><span className="bold">In-comlete:</span> The student's work duration is less than the shift duration.</p>
            <p><span className="bold">Out of Range:</span> The student's clock in/out ip address is not in range.</p>
            <p><span className="bold"><span className="tag is-warning">No Hours</span>:</span> The student had shifts, but did not clock any hours in the time range.</p>
            <p><span className="bold"><span className="tag is-warning is-light">No Shifts</span>:</span> The student did not have any shifts in the time range.</p>
            <br />
         </div>
      </>

   }

   render() {
      return (
         <div>
            {this.headerAndInstructions()}
            {this.drawShifts()}
            {this.drawSelectedEmployee()}
         </div>
      )
   }
}

export default SupervisorView